import * as yup from "yup";

export const orderSchema = yup.object({
  id: yup.string().required(),
  orderId: yup.number().required(),
  orderNo: yup.string().required(),
  contactEmail: yup.string().required(),
});

export type Order = yup.InferType<typeof orderSchema>;

export const ExpWindowPat = /([0-9]+)([Mhdw])/;

export const fulfillmentConfigSchema = yup.object({
  productId: yup.number().optional().nullable(),
  expWindow: yup.string().matches(ExpWindowPat).required(),
});

export type FulfillmentConfig = yup.InferType<typeof fulfillmentConfigSchema>;

export const fulfillmentConfigByDomainSchema = yup.object({
  birthRecords: fulfillmentConfigSchema,
  deathRecords: fulfillmentConfigSchema,
});

export type FulfillmentConfigByDomain = yup.InferType<
  typeof fulfillmentConfigByDomainSchema
>;

export type ExpPeriod = "M" | "h" | "d" | "w";

export type FulfillmentConfigState = {
  productId: number | null | undefined;
  expValue: number;
  expPeriod: ExpPeriod;
};

export type FulfillmentConfigStateByDomain = {
  birthRecords: FulfillmentConfigState;
  deathRecords: FulfillmentConfigState;
};

export function decodeFulfillmentConfigByDomain(
  conf: FulfillmentConfigByDomain,
): FulfillmentConfigStateByDomain {
  const decodeFulfillmentConfig = (
    cfg: FulfillmentConfig,
  ): FulfillmentConfigState => {
    const parts = cfg.expWindow.match(ExpWindowPat);
    if (!parts) {
      // This shouldn't ever actually happen cause the api does its own validation,
      // but this satisfies the type checker and if the client ever generates its
      // own FulfillmentConfigs this is a good check for devs.
      throw Error(`${cfg} has invalid expWindow value.`);
    }
    let value = Number(parts[1]);
    let period = parts[2] as ExpPeriod;
    return {
      productId: cfg.productId,
      expPeriod: period,
      expValue: value,
    };
  };

  return Object.entries(conf).reduce(
    (prev, [k, cfg]) => ({ ...prev, [k]: decodeFulfillmentConfig(cfg) }),
    {} as FulfillmentConfigStateByDomain,
  );
}

export function encodeFulfillmentConfigStateByDomain(
  conf: FulfillmentConfigStateByDomain,
): FulfillmentConfigByDomain {
  const encodeFulfillmentConfigState = (
    cfg: FulfillmentConfigState,
  ): FulfillmentConfig => ({
    productId: cfg.productId,
    expWindow: `${cfg.expValue}${cfg.expPeriod}`,
  });

  return Object.entries(conf).reduce(
    (prev, [k, cfg]) => ({ ...prev, [k]: encodeFulfillmentConfigState(cfg) }),
    {} as FulfillmentConfigByDomain,
  );
}

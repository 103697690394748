import { Button, Checkbox, Select, Spinner } from "@shopify/polaris";
import { useState } from "react";
import Card, { NavCard } from "../shared/Card";
import Stack from "../shared/Stack";
import { Heading, PText, Subheading } from "../shared/TextComponents";
import { hasPermissions } from "./auth/authutils";
import BreadcrumbPage from "./BreadcrumbPage";
import { FormField } from "./Form";
import {
  useFulfillmentActiveStatus,
  useFulfillmentConfigByDomain,
  useToggleFulfillmentActivation,
  useUpdateFulfillmentConfigByDomain,
} from "./hooks/fulfillmentHooks";
import { CurrentUserData } from "./schemas/core";
import {
  decodeFulfillmentConfigByDomain,
  encodeFulfillmentConfigStateByDomain,
  ExpPeriod,
  FulfillmentConfigByDomain,
  FulfillmentConfigState,
  FulfillmentConfigStateByDomain,
} from "./schemas/fulfillment";
import { DefaultPageProps } from "./utils/shared";

export default function Orders() {}

function ShopifyFulfillmentStatusBtn({
  currentUserData,
}: {
  currentUserData: CurrentUserData;
}) {
  const { data: active, isLoading } = useFulfillmentActiveStatus(
    currentUserData.accessToken,
  );
  const mutation = useToggleFulfillmentActivation();

  const displayText = active
    ? "Shopify is currently connected to Frostbite for automated fulfillment," +
      " click to deactivate"
    : "Shopify is not currently connected to Frostbite for automated fulfillment," +
      " click to activate";

  const spinner = isLoading && <Spinner size="small" />;

  return (
    <Stack direction="row">
      {spinner}
      <Checkbox
        checked={active}
        onChange={async (value) => {
          await mutation.mutateAsync({
            accessToken: currentUserData.accessToken,
            activate: value,
          });
        }}
        disabled={isLoading || mutation.isLoading}
        label={
          isLoading
            ? "Checking Shopify for automated fulfillment status..."
            : displayText
        }
      />
    </Stack>
  );
}

interface FulfillmentConfigEntryRowProps {
  label: string;
  stateKey: keyof FulfillmentConfigStateByDomain;
  configState: FulfillmentConfigStateByDomain;
  setConfigState: (config: FulfillmentConfigStateByDomain) => void;
}

function FulfillmentConfigEntryRow({
  label,
  stateKey,
  configState,
  setConfigState,
}: FulfillmentConfigEntryRowProps) {
  const changeState = (k: keyof FulfillmentConfigState, value: string) => {
    let cfgState = { ...configState[stateKey] };
    if (k === "productId") {
      cfgState = { ...cfgState, productId: Number(value) };
    } else if (k === "expValue") {
      cfgState = { ...cfgState, expValue: Number(value) };
    } else {
      cfgState = { ...cfgState, expPeriod: value as ExpPeriod };
    }
    let newState = { ...configState, [stateKey]: cfgState };
    setConfigState(newState);
  };
  return (
    <Stack>
      <Subheading>{label}</Subheading>
      <Stack direction="row">
        <FormField
          type="number"
          label="Product ID"
          value={configState[stateKey].productId?.toString()}
          onChange={(value) => changeState("productId", value)}
          onClearButtonClick={() => {
            let newState = {
              ...configState,
              [stateKey]: { ...configState[stateKey], productId: null },
            };
            setConfigState(newState);
          }}
        />
        <FormField
          type="number"
          label="Expiration Window"
          value={configState[stateKey].expValue.toString()}
          onChange={(value) => changeState("expValue", value)}
          required
        />
        <Select
          label="Expiration Period"
          options={[
            { label: "minutes", value: "M" },
            { label: "hours", value: "h" },
            { label: "days", value: "d" },
            { label: "weeks", value: "w" },
          ]}
          value={configState[stateKey].expPeriod}
          onChange={(value) => changeState("expPeriod", value)}
        />
      </Stack>
    </Stack>
  );
}

interface FulfillmentConfigEntryProps {
  configByDomain: FulfillmentConfigByDomain;
  currentUserData: CurrentUserData;
}

function FulfillmentConfigEntry({
  configByDomain,
  currentUserData,
}: FulfillmentConfigEntryProps) {
  const origConfigState = decodeFulfillmentConfigByDomain(configByDomain);
  const [configState, setConfigState] = useState(
    decodeFulfillmentConfigByDomain(configByDomain),
  );
  const [configChanged, setConfigChanged] = useState(false);

  const mutation = useUpdateFulfillmentConfigByDomain();

  return (
    <Stack>
      {Object.keys(configState).map((configKey) => (
        <FulfillmentConfigEntryRow
          stateKey={configKey as keyof FulfillmentConfigStateByDomain}
          label={configKey}
          configState={configState}
          setConfigState={(configState) => {
            setConfigChanged(true);
            setConfigState(configState);
          }}
        />
      ))}
      <Stack direction="row" justify="flex-end" align="center">
        {mutation.isLoading && <Spinner size="small" />}
        {mutation.isLoading && <PText>Saving config...</PText>}
        <Button
          onClick={() => {
            setConfigState(origConfigState);
            setConfigChanged(false);
          }}
          disabled={mutation.isLoading}
        >
          Reset
        </Button>
        <Button
          variant="primary"
          tone="success"
          disabled={!configChanged || mutation.isLoading}
          onClick={async () => {
            await mutation.mutateAsync({
              accessToken: currentUserData.accessToken,
              config: encodeFulfillmentConfigStateByDomain(configState),
            });
            setConfigChanged(false);
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
}

export function FulfillmentsConfigPage({ currentUserData }: DefaultPageProps) {
  const webHookArea = hasPermissions(
    currentUserData,
    "manage_shopify_webhooks",
  ) && <ShopifyFulfillmentStatusBtn currentUserData={currentUserData} />;

  const { data: fulfillmentConfigByDomain, isLoading } =
    useFulfillmentConfigByDomain(currentUserData.accessToken);

  const spinner = isLoading && <Spinner />;

  const fulfillmentConfigEntries = fulfillmentConfigByDomain && (
    <FulfillmentConfigEntry
      configByDomain={fulfillmentConfigByDomain}
      currentUserData={currentUserData}
    />
  );

  return (
    <BreadcrumbPage>
      <Card>
        <Stack>
          <Heading>Fulfillments Configuration</Heading>
          {webHookArea}
          <PText>
            Manage the relevant configuration for automated fulfillment of
            orders of records in each area below.
          </PText>
          <PText>
            Product ID refers to the Shopify product ID of the product that,
            when purchased, should be fulfilled by this system. If you don't
            know how to find a product's product ID, consult the Shopify
            administrator.
          </PText>
          <PText>
            Expiration Window and Period controls how long temporary links to
            purchased certificate media will be valid from time of fulfillment.
            You can set different expiration periods for each record set.
          </PText>
          {spinner || fulfillmentConfigEntries}
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}

export function FulfillmentsAdminPage() {
  return (
    <BreadcrumbPage>
      <Card>
        <Stack>
          <Heading>Fulfillments Admin</Heading>
          <NavCard heading="Fulfillment Config" url="./config">
            Manage fulfillment configuration, including assigning products to
            fulfill and setting expiration windows for each dataset.
          </NavCard>
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}

import { useMutation, useQuery, useQueryClient } from "react-query";
import { RQUERY_STALE_TIME } from "../../env";
import { axiosInstance, baseAxiosConfig } from "../api/shared";
import {
  FulfillmentConfigByDomain,
  fulfillmentConfigByDomainSchema,
} from "../schemas/fulfillment";

const shopifyFulfillmentRoot = "/shopify/fulfillment";
const shopifyFulfillmentStatus = "shopify-fulfillment-status";
const fulfillmentsEndpoint = "fulfillments";
const fulfillmentsConfigEndpoint = "config";

export function useFulfillmentActiveStatus(accessToken: string) {
  const getActiveStatus = async (accessToken: string): Promise<boolean> => {
    const resp = await axiosInstance.get(
      `${shopifyFulfillmentRoot}/status`,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  };
  return useQuery(
    [shopifyFulfillmentStatus],
    async () => await getActiveStatus(accessToken),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useToggleFulfillmentActivation() {
  const toggleActivation = async (
    accessToken: string,
    activate: boolean,
  ): Promise<null> => {
    const urlSuffix = activate ? "/activate" : "/deactivate";
    await axiosInstance.put(
      `${shopifyFulfillmentRoot}${urlSuffix}`,
      null,
      baseAxiosConfig(accessToken),
    );
    return null;
  };

  const queryClient = useQueryClient();

  return useMutation(
    ({ accessToken, activate }: { accessToken: string; activate: boolean }) => {
      return toggleActivation(accessToken, activate);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([shopifyFulfillmentStatus]);
      },
    },
  );
}

export function useFulfillmentConfigByDomain(accessToken: string) {
  const getFulfillmentConfigByDomain = async (
    accessToken: string,
  ): Promise<FulfillmentConfigByDomain> => {
    const resp = await axiosInstance.get(
      `/${fulfillmentsEndpoint}/${fulfillmentsConfigEndpoint}`,
      baseAxiosConfig(accessToken),
    );
    const result = await fulfillmentConfigByDomainSchema.validate(resp.data);
    return result;
  };

  return useQuery(
    [fulfillmentsEndpoint, fulfillmentsConfigEndpoint],
    async () => await getFulfillmentConfigByDomain(accessToken),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useUpdateFulfillmentConfigByDomain() {
  const updateFulfillmentConfigByDomain = async (
    accessToken: string,
    config: FulfillmentConfigByDomain,
  ) => {
    await axiosInstance.post(
      `/${fulfillmentsEndpoint}/${fulfillmentsConfigEndpoint}`,
      config,
      baseAxiosConfig(accessToken),
    );
  };

  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      config,
    }: {
      accessToken: string;
      config: FulfillmentConfigByDomain;
    }) => {
      return updateFulfillmentConfigByDomain(accessToken, config);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([
          fulfillmentsEndpoint,
          fulfillmentsConfigEndpoint,
        ]);
      },
    },
  );
}
